<template>
  <v-bottom-sheet
    v-model="showPopup"
    :persistent="true"
    >
    <v-card class="card-content">
      <div class="d-flex justify-space-between py-3 px-5">
        <v-btn icon small fab disabled></v-btn>
        <div
          class="text-center templateLabel f-s-20 f-w-900"
        > {{ title }} </div>
        <v-btn icon small fab @click="$emit('confirm')">
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </div>

      <v-card-text v-if="$route.path.includes('disbursement')">
        <v-alert
          dense
          text
          border="left"
          color="var(--theme_primary)"
          class="f-s-12"
        >
          <span class="text-red">*</span> The installment amount is calculated based on the actual days in
          the billing cycle, which may
          be slightly different from the regular amount.
        </v-alert>
      </v-card-text>
      <v-card-text>
        <div class="records-show-box">
          <vue-perfect-scrollbar class="scroll">
            <div
              v-for="(item, index) in paymentInstallment"
              :key="index"
              class="records-item"
            >
              <div class="records-item-time pull-left">
                <div>
                  {{ item.transactionDate }}
                </div>
                <div v-if="index !== (paymentInstallment.length - 1)" class="records-item-line"></div>
              </div>
              <div class="records-item-content pull-left" :class="item.transactionStatus === assist.dataType.TransactionStatusEnum.CHECKED.value ? 'checked' : ''">
                <div class="height-24 mt-3 mx-4 d-flex">
                  <div class="records-item-amount">
                    {{ assist.currency.format(item.amount) }}
                    <span v-if="regularAmount !== assist.currency.format(item.amount) && assist.tool.isNotEmpty(regularAmount)" class="text-red">*</span>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="records-item-text-name">
                    {{assist.tool.getEnumTextByValue(assist.dataType.TransactionTypeEnum, item.transactionType).toUpperCase() }}
                  </div>
                  <template v-if="false">
                    <v-spacer></v-spacer>
                    <v-icon :color="getColor(item)"
                            @click="onClickAction(item)"
                    >reorder</v-icon>
                  </template>
                </div>
              </div>
            </div>
          </vue-perfect-scrollbar>
        </div>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { Tool, DataType, Currency } from '@/assets/js/util'

const tool = new Tool()
const currency = new Currency()
export default {
  name: 'preview-payment-schedule',
  model: {
    prop: 'showPreview',
    event: 'change'
  },
  props: {
    showPreview: {
      type: Boolean,
      default: false
    },
    height: {
      type: [String, Number],
      default: 150
    },
    paymentInstallment: {
      type: Array,
      default: () => []
    },
    regularAmount: {
      type: String,
      default: ''
    },
    TypeEnum: {
      type: Array,
      default: () => []
    },
    dateYear: {
      type: String,
      default: ''
    },
    dateMonth: {
      type: String,
      default: ''
    },
    amount: {
      type: String,
      default: ''
    },
    hasAction: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      assist: {
        tool: tool,
        dataType: DataType,
        currency: currency
      },
      showPopup: false
    }
  },
  mounted () {
    this.showPopup = this.showPreview
  },
  watch: {
    showPreview () {
      this.showPopup = this.showPreview
    },
    showPopup (val) {
      this.$emit('change', this.showPopup)
      if (val) {
        // CommonRequest.addCustomerBehaviorRecords(
        //   this.$route.path,
        //   DataType.EventTypeEnum.ENTER_PAGE.value,
        //   DataType.EventSourceEnum.POST_ORIGINATION_ENTER_LEAVE_LOAN_RECORDS.value
        // )
      } else {
        // CommonRequest.addCustomerBehaviorRecords(
        //   this.$route.path,
        //   DataType.EventTypeEnum.LEAVE_PAGE.value,
        //   DataType.EventSourceEnum.POST_ORIGINATION_ENTER_LEAVE_LOAN_RECORDS.value
        // )
      }
    }
  },
  methods: {
    getColor (paymentSchedule) {
      const action = paymentSchedule.action
      const pickerData = []
      for (const item of action) {
        const key = Object.keys(item)[0]
        if (item[key] === DataType.ChangeTypeEnum.MANUAL_REJECT.value ||
          item[key] === DataType.ChangeTypeEnum.MANUAL_CHECKED.value) {
          continue
        }
        pickerData.push({ label: key, value: item[key] })
      }
      return pickerData.length === 0 ? 'gray' : 'var(--theme_secondary)'
    },
    onClickAction (paymentSchedule) {
      this.$emit('single-action', paymentSchedule)
    }
  }
}
</script>

<style lang="sass" scoped>
  .card-content
    min-height: 260px
    border-radius: 12px 12px 0 0

  .records-show-box
    width: 100%
    height: 500px

  .scroll
    height: 100%
    width: 103%

  .records-item
    margin-top: 16px
    height: 50px
    width: calc(100% - 10px)

  .records-item-time
    width: 80px
    height: 28px
    font-size: 12px
    margin-top: 18px
    font-weight: 300
    text-align: left
    color: #9ba1b7
    line-height: 14px

  .records-item-line
    width: 1px
    height: 22px
    margin-top: 12px
    margin-left: 30px
    border: 1px solid #eef1f8

  .records-item-content
    margin-left: 20px
    width: calc(100% - 110px)
    height: 50px
    border: 1px solid #eef1f8
    border-radius: 4px 12px 12px 12px
    position: relative
  .checked
    background: #cffcd3
  .records-item-amount
    height: 24px
    font-size: 14px
    font-weight: 400
    color: #000410
    line-height: 24px

  .records-item-text-name
    height: 24px
    font-size: 12px
    font-weight: 400
    color: #000410
    line-height: 24px
  .templateLabel
    line-height: 40px
</style>
